import {
  LineItemDraft,
  LocalizedString,
  MyShoppingListAddLineItemAction,
  MyShoppingListChangeNameAction,
  MyShoppingListRemoveLineItemAction,
  MyShoppingListSetCustomFieldAction,
} from '@commercetools/platform-sdk'

export const addToShoppingList = (lineItem: LineItemDraft | undefined): MyShoppingListAddLineItemAction => {
  const { sku, quantity, productId } = lineItem || {}

  const actionDraft = {
    action: 'addLineItem',
    quantity: quantity || 1,
  } as const

  return sku ? { ...actionDraft, sku } : { ...actionDraft, productId }
}

export const removeFromShoppingList = (lineItemId: string, quantity?: number): MyShoppingListRemoveLineItemAction => ({
  action: 'removeLineItem',
  lineItemId,
  quantity,
})

export const changeNameOfShoppingList = (name: LocalizedString): MyShoppingListChangeNameAction => ({
  action: 'changeName',
  name,
})

export const updateCustomModifiedByField = (name: string, value: any): MyShoppingListSetCustomFieldAction => ({
  action: 'setCustomField',
  name,
  value,
})
