import { useEffect } from 'react'

/**
 * For focusing on a field inside a Chakra modal.
 *
 * `autoFocus` in input will not work, because Chakra UI's sets focus
 * onto the modal close button itself.
 */
function useFocusOnField(inputId: string, isOpen: boolean = true) {
  useEffect(() => {
    if (isOpen) {
      // Needs to be inside setTimeout, because Chakra UI's Modal component is not yet mounted when this effect is called.
      setTimeout(() => document.getElementById(inputId)?.focus())
    }
  }, [inputId, isOpen])
}

export default useFocusOnField
