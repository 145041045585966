import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  ModalBody,
  ModalFooter,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import type { ShoppingListLineItemDraft } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useId } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { changeNameOfShoppingList } from 'commercetools/api/actions/shopping-list'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { ShoppingListQueryResult } from 'commercetools/hooks/use-shopping-list'
import { useShoppingLists } from 'commercetools/hooks/use-shopping-lists'
import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import useFocusOnField from 'hooks/useFocusOnField'
import { useToastAlert } from 'hooks/useToastAlert'
import { appInsights } from 'logging/appInsight'

const SHOPPINGLIST_CUSTOM_TYPE_KEY = 'shoppingList-normetShoppingList'

export type OrderTemplateCreateModalFormProps = {
  onClose: VoidFunction
  lineItems: ReadonlyArray<ShoppingListLineItemDraft>
  shoppingList?: ShoppingListQueryResult
}

interface OrderTemplateCreateRenameSchema {
  shoppingListName: string
}

const TAG = 'OrderTemplateCreateModalForm'

export const OrderTemplateCreateModalForm = ({
  shoppingList,
  lineItems,
  onClose,
}: OrderTemplateCreateModalFormProps): JSX.Element => {
  const { t, lang } = useTranslation('common')
  const toast = useToastAlert()

  const { isOpen: isError, onOpen: showAPIError, onClose: closeAPIError } = useDisclosure()

  const { shoppingLists, create, update } = useShoppingLists()
  const [customer] = useCustomer()
  const businessUnit = useBusinessUnit()

  const { register, formState, handleSubmit } = useForm<OrderTemplateCreateRenameSchema>({
    defaultValues: { shoppingListName: getLocalizedString(shoppingList?.data?.name, lang) ?? '' },
    mode: 'onChange',
  })
  const { isSubmitting, isValid, isSubmitted, errors } = formState

  const createNewShoppingList = useCallback(
    (data: OrderTemplateCreateRenameSchema) =>
      create({
        name: { en: data.shoppingListName },
        lineItems: [...lineItems],
        custom: {
          type: {
            typeId: 'type',
            key: SHOPPINGLIST_CUSTOM_TYPE_KEY,
          },
          fields: {
            modifiedBy: {
              typeId: 'customer',
              id: customer?.data?.id,
            },
            ctBusinessUnitReference: {
              typeId: 'business-unit',
              id: businessUnit.id,
            },
          },
        },
      }).then(() => {
        toast({ title: t('order-template-added'), status: 'success' })
        shoppingLists.refetch()
        onClose()
      }),
    [businessUnit.id, create, customer?.data?.id, lineItems, onClose, shoppingLists, t, toast],
  )

  const updateShoppingListName = useCallback(
    (data: OrderTemplateCreateRenameSchema) => {
      if (data.shoppingListName && shoppingList?.data?.id) {
        const newName = { en: data.shoppingListName }
        return update(customer?.data?.id ?? '', shoppingList?.data?.id, changeNameOfShoppingList(newName)).then(() => {
          shoppingList.refetch()
          toast({ title: t('name-was-updated'), status: 'success' })
          onClose()
        })
      } else {
        toast({ title: t('something-goes-wrong'), status: 'error' })
      }
    },
    [customer?.data?.id, onClose, shoppingList, t, toast, update],
  )

  const onSubmit = useCallback<SubmitHandler<OrderTemplateCreateRenameSchema>>(
    async (data) => {
      try {
        closeAPIError()
        if (shoppingList?.data?.id) {
          await updateShoppingListName(data)
        } else if (data.shoppingListName) {
          await createNewShoppingList(data)
        }
      } catch (error) {
        showAPIError()
        console.error(error)
        appInsights.trackException({ exception: error as Error })
      }
    },
    [closeAPIError, createNewShoppingList, shoppingList?.data?.id, showAPIError, updateShoppingListName],
  )

  const inputId = useId()
  useFocusOnField(inputId, true)

  return (
    <form name={TAG} data-testid={TAG} onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        {isSubmitted && isError && (
          <Alert status="error" mb="4">
            <AlertIcon />
            {t('shopping-list-error')}
          </Alert>
        )}

        <Divider borderBottomWidth={4} borderColor="primary.grey.100" />
        <Stack spacing={4} py={4}>
          <FormControl isInvalid={!!errors.shoppingListName}>
            <InputGroup>
              <Input
                id={inputId}
                placeholder={t('shopping-list-placeholder')}
                maxLength={30}
                {...register('shoppingListName', {
                  required: t('common:error-shopping-list-name-required'),
                })}
              />
              <FormLabel htmlFor="newPassword">{t('shopping-list-name')}</FormLabel>
            </InputGroup>
            <FormErrorMessage>{errors.shoppingListName && errors.shoppingListName.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || (isSubmitted && !isValid)}
        >
          {t('save')}
        </Button>

        <Button variant="ghost" color="primary.red" onClick={onClose}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </form>
  )
}
