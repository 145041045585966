import {
  ErrorObject,
  ShoppingList,
  ShoppingListDraft,
  ShoppingListPagedQueryResponse,
  ShoppingListUpdateAction,
} from '@commercetools/platform-sdk'
import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { updateCustomModifiedByField } from 'commercetools/api/actions/shopping-list'
import {
  createShoppingList,
  deleteShoppingList,
  updateShoppingList,
  fetchBuShoppingLists,
} from 'commercetools/api/shopping-lists'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { appInsights } from 'logging/appInsight'

type UseShoppingLists = {
  shoppingLists: UseQueryResult<ShoppingListPagedQueryResponse, ErrorObject>
  shoppingListSkus: (string | undefined)[]
  create: (shoppingListDraft: ShoppingListDraft | undefined) => Promise<ShoppingList | undefined>
  update: (
    customerId: string,
    listId: string,
    actions: ShoppingListUpdateAction | ShoppingListUpdateAction[],
    message?: string,
  ) => Promise<ShoppingList | undefined>
  remove: (id: string) => Promise<ShoppingList | undefined>
}

export const useShoppingListsQuery = (): UseQueryResult<ShoppingListPagedQueryResponse, ErrorObject> => {
  const businessUnit = useBusinessUnit()

  return useQuery<ShoppingListPagedQueryResponse, ErrorObject, ShoppingListPagedQueryResponse>(
    ['shopping-lists', businessUnit.id],
    () => fetchBuShoppingLists(businessUnit.id),
    { enabled: Boolean(businessUnit.id) },
  )
}

export const useShoppingLists = (): UseShoppingLists => {
  const queryClient = useQueryClient()
  const shoppingLists = useShoppingListsQuery()
  const getVersion = useCallback(
    (id: string) => {
      return shoppingLists?.data?.results?.find((shoppingList) => shoppingList.id === id)?.version || 0
    },
    [shoppingLists?.data?.results],
  )

  const shoppingListSkus = useMemo(
    () =>
      Array.from(
        new Set(
          shoppingLists?.data?.results?.flatMap(
            ({ lineItems }) => lineItems?.map((lineItem) => lineItem?.variant?.sku),
          ),
        ),
      ),
    [shoppingLists],
  )

  const create = useCallback(
    async (shoppingListDraft: ShoppingListDraft | undefined): Promise<ShoppingList | undefined> => {
      const result = await createShoppingList(shoppingListDraft)
      shoppingLists.refetch()
      return result
    },
    [shoppingLists],
  )

  const remove = useCallback(
    async (listId: string): Promise<ShoppingList | undefined> => {
      try {
        const result = await deleteShoppingList(listId, getVersion(listId))
        shoppingLists.refetch()
        return result
      } catch (error: any) {
        console.error(error)
        appInsights.trackException({ exception: new Error(error) })
      }
    },
    [getVersion, shoppingLists],
  )

  const update = useCallback(
    async (
      customerId: string,
      listId: string,
      actions: ShoppingListUpdateAction | ShoppingListUpdateAction[],
    ): Promise<ShoppingList | undefined> => {
      try {
        const version = getVersion(listId)

        const actionsArray: ShoppingListUpdateAction[] = Array.isArray(actions) ? actions : [actions]
        actionsArray.push(
          updateCustomModifiedByField('modifiedBy', {
            typeId: 'customer',
            id: customerId,
          }),
        )

        const result = await updateShoppingList(listId, { version, actions: actionsArray })
        queryClient.refetchQueries(['shopping-list', result.id])
        shoppingLists.refetch()
        return result
      } catch (error: any) {
        console.error(error)
        appInsights.trackException({ exception: new Error(error) })
      }
    },
    [getVersion, queryClient, shoppingLists],
  )

  return { shoppingLists, shoppingListSkus, create, update, remove }
}
